import React, { useEffect, useState } from "react";
import Banner from "../components/mainbanner/banner";
import Navbar from "../components/navbar/navbar";
import banner from "../assets/imgs/background2.jpg";
import "../assets/css/catalog.css";
import { Link, useParams } from "react-router-dom";
import LoaderComponent from "../components/LoaderComponent";
import logo from "../assets/imgs/logo/logo_origjinal.png";

export default function Catalog({ bricks }) {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [imgAnimate, setImgAnimate] = useState(false);
  const images = [banner, banner];
  const theme = {
    color: "rgba(218, 0, 0, 0.8)",
    background: "",
    animation: "banner 60s linear infinite",
    logoImage: logo,
  };
  const [isClay, setIsClay] = useState(false);

  useEffect(() => {
    if (id === "2") {
      setIsClay(true);
    } else {
      setIsClay(false);
    }
  }, [id]);
  useEffect(() => {
    if (!loading) {
      setImgAnimate(true);
    }
  }, [loading]);

  const brickClass1 = imgAnimate ? "animation" : "";
  const brickClass2 = isClay ? "clay-bricks" : "";

  return (
    <>
      {loading && <LoaderComponent />}
      <Banner images={images} theme={theme} />
      <Navbar theme={theme} />
      <div className="bricks-card-container">
        {bricks
          .filter((brick) => brick.category === id)
          .map((brick, index) => (
            <Link to={"/" + brick.id} className={`bricks-card ${brickClass1} ${brickClass2}`} key={index} style={{ animationDelay: `${index * 0.1}s` }}>
              <img
                src={brick.mainImage}
                alt="brick"
                onLoad={() => {
                  if (index === bricks.filter((brick) => brick.category === id).length - 1) setLoading(false);
                }}
              />
            </Link>
          ))}
      </div>
    </>
  );
}
