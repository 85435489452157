import React, { useEffect, useState } from "react";
import Banner from "../components/mainbanner/banner";
import Navbar from "../components/navbar/navbar";
import logo from "../assets/imgs/logo/logo_origjinal.png";
import banner from "../assets/imgs/background2.jpg";
import { moreInfo } from "../assets/js/data";
import { useParams } from "react-router-dom";
import "../assets/css/info.css";
import brickImg from "../assets/imgs/brick_img.png";

export default function Info() {
  const { id } = useParams();
  const images = [banner, banner];
  const theme = {
    color: "rgba(218, 0, 0, 0.8)",
    background: "",
    animation: "banner 60s linear infinite",
    logoImage: logo,
  };
  const [selectedLanguage, setSelectedLanguage] = useState("al");
  const [moreInfoText, setMoreInfoText] = useState({});
  const [isClay, setIsClay] = useState(false);

  useEffect(() => {
    setSelectedLanguage(localStorage.getItem("lang"));
  }, []);

  useEffect(() => {
    setMoreInfoText(moreInfo[selectedLanguage]);
  }, [selectedLanguage]);

  useEffect(() => {
    if (id === "2") {
      setIsClay(true);
    } else {
      setIsClay(false);
    }
  }, [id]);

  const handleLangChange = (lang) => {
    setSelectedLanguage(lang);
  };
  return (
    <>
      <Navbar theme={theme} onLangChange={handleLangChange} />
      <Banner images={images} theme={theme} />
      <div className="more-info-page">
        {isClay && (
          <div className="clay-info-content">
            <h1>{moreInfoText?.h1}</h1>
            <div className="product-moreInfo-text">
              <p className="bold">{moreInfoText?.p1}</p>
            </div>
            <div className="product-moreInfo-text">
              <p>{moreInfoText?.l1}</p>
              <p>{moreInfoText?.l1_1}</p>
            </div>
            <div className="product-moreInfo-text">
              <p>{moreInfoText?.l2}</p>
              <p>{moreInfoText?.l2_1}</p>
            </div>
            <div className="product-moreInfo-text">
              <p>{moreInfoText?.l3}</p>
              <p>{moreInfoText?.l3_1}</p>
            </div>
            <div className="product-moreInfo-text">
              <p>{moreInfoText?.l4}</p>
              <p>{moreInfoText?.l4_1}</p>
            </div>
            <div className="product-moreInfo-text">
              <p>{moreInfoText?.l5}</p>
              <p>{moreInfoText?.l5_1}</p>
            </div>
            <div className="product-moreInfo-text">
              <p>{moreInfoText?.l6}</p>
              <p>{moreInfoText?.l6_1}</p>
            </div>
            <div className="product-moreInfo-text">
              <p className="bold">{moreInfoText?.h2}</p>
              <p className="bold">{moreInfoText?.h2_1}</p>
            </div>
          </div>
        )}

        {!isClay && (
          <div className="clay-info-content">
            <h1>{moreInfoText?.h1}</h1>
            <div className="product-moreInfo-text">
              <p className="bold">{moreInfoText?.info_concrete?.h2}</p>
            </div>
            <div className="product-moreInfo-text">
              <p>{moreInfoText?.info_concrete?.p1}</p>
            </div>
            <div className="product-image-infos">
              <div className="product-image-info">
                <span className="info info-1">6cm</span>
                <span className="info info-2">20cm</span>
                <span className="info info-3">2cm</span>
                <img src={brickImg} alt="" />
              </div>
              <div className="product-image-info">
                <span className="info info-1">6cm</span>
                <span className="info info-2">20cm</span>
                <span className="info info-3">4.5cm</span>
                <img src={brickImg} alt="" />
              </div>
              <div className="product-image-info">
                <span className="info info-1">6cm</span>
                <span className="info info-2">20cm</span>
                <span className="info info-3">6cm</span>
                <img src={brickImg} alt="" />
              </div>
            </div>
            <div className="product-moreInfo-text">
              <p>{moreInfoText?.info_concrete?.p2}</p>
            </div>
            <div className="product-moreInfo-text">
              <p className="bold">{moreInfoText?.info_concrete?.p3}</p>
              <p className="bold">{moreInfoText?.info_concrete?.p4}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
