import React, { useEffect, useState } from "react";
import Navbar from "../components/navbar/navbar";
import Banner from "../components/mainbanner/banner";
import banner from "../assets/imgs/background1.jpg";
import logo from "../assets/imgs/logo/logo_origjinal_1.png";
import "../assets/css/homepage.css";
import LoaderComponent from "../components/LoaderComponent";

export default function Homepage({ bricks }) {
  const images = [banner, banner];
  const theme = {
    color: "rgba(218, 0, 0, 0.8)",
    background: "linear-gradient(180deg, rgba(0,0,0,0) 50%, rgba(218, 0, 0, 0.8) 83%)",
    animation: "banner 60s linear infinite",
    logoImage: logo,
  };
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [loading]);

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const bricks = document.querySelectorAll(".header-content-brick");

    let index = 0;
    let indexAfter = 0;
    bricks[index].classList.add("active");
    setInterval(() => {
      indexAfter = index;
      index = index === bricks.length - 1 ? 0 : index + 1;
      bricks[indexAfter].classList.add("after");
      bricks[indexAfter].classList.remove("active");
      bricks[index].classList.add("active");

      setTimeout(() => {
        bricks[indexAfter].classList.remove("after");
      }, 1000);
    }, 2000);
  }, []);
  return (
    <>
      {loading && <LoaderComponent />}
      <div className="homepage">
        <Banner images={images} theme={theme} />
        <Navbar theme={theme} />
        <div className="header-content">
          <div className="header-content-text">
            <p>Welcome to</p>
            <h1>Tulla Aksi</h1>
          </div>
          <div className="header-content-bricks">
            {bricks
              .filter((brick) => brick.category === "1")
              .map((brick, index) => (
                <div className="header-content-brick" style={{ backgroundImage: `url(${brick.txtName})` }} key={index}></div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
