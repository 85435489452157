import React from "react";
import "./banner.css";

export default function Banner({ images, theme }) {
  return (
    <div className="main-banner">
      <div className="banner-images" style={{ animation: theme.animation }}>
        {images.map((image, index) => (
          <div className="banner-image" style={{ backgroundImage: `url(${image})` }} key={index}></div>
        ))}
      </div>
      <div className="banner-highlight" style={{ backgroundColor: "rgb(0 0 0 / 8%)" }}></div>
      <div className="banner-highlight" style={{ background: theme.background }}></div>
    </div>
  );
}
