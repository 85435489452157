import mainImage1 from "../imgs/bricks/1-cop-tulle.jpg";
import mainImage2 from "../imgs/bricks/2-cop-tulle.jpg";
import mainImage3 from "../imgs/bricks/3-cop-tulle.jpg";
import mainImage4 from "../imgs/bricks/4-cop-tulle.jpg";
import mainImage5 from "../imgs/bricks/5-cop-tulle.jpg";
import mainImage6 from "../imgs/bricks/6-cop-tulle.jpg";
import mainImage7 from "../imgs/bricks/7-cop-tulle.jpg";
import mainImage8 from "../imgs/bricks/8-cop-tulle.jpg";
import mainImage9 from "../imgs/bricks/9-cop-tulle.jpg";
import mainImage10 from "../imgs/bricks/10-cop-tulle.jpg";
import mainImage11 from "../imgs/bricks/11-cop-tulle.jpg";
import mainImage12 from "../imgs/bricks/12-cop-tulle.jpg";
import mainImage13 from "../imgs/bricks/13-cop-tulle.jpg";
import mainImage14 from "../imgs/bricks/14-cop-tulle.jpg";
import mainImage15 from "../imgs/bricks/15-cop-tulle.jpg";
import mainImage16 from "../imgs/bricks/16-cop-tulle.jpg";
import mainImage17 from "../imgs/bricks/17-cop-tulle.jpg";
import mainImage18 from "../imgs/bricks/18-cop-tulle.jpg";
import mainImage19 from "../imgs/bricks/19-cop-tulle.jpg";
import mainImage20 from "../imgs/bricks/20-cop-tulle.jpg";
import mainImage21 from "../imgs/bricks/21-cop-tulle.jpg";

import imageWithColors1 from "../imgs/bricks/1.jpg";
import imageWithColors2 from "../imgs/bricks/2.jpg";
import imageWithColors3 from "../imgs/bricks/3.jpg";
import imageWithColors4 from "../imgs/bricks/4.jpg";
import imageWithColors5 from "../imgs/bricks/5.jpg";
import imageWithColors6 from "../imgs/bricks/6.jpg";
import imageWithColors7 from "../imgs/bricks/7.jpg";
import imageWithColors8 from "../imgs/bricks/8.jpg";
import imageWithColors9 from "../imgs/bricks/9.jpg";
import imageWithColors10 from "../imgs/bricks/10.jpg";
import imageWithColors11 from "../imgs/bricks/11.jpg";
import imageWithColors12 from "../imgs/bricks/12.jpg";
import imageWithColors13 from "../imgs/bricks/13.jpg";
import imageWithColors14 from "../imgs/bricks/14.jpg";
import imageWithColors15 from "../imgs/bricks/15.jpg";
import imageWithColors16 from "../imgs/bricks/16.jpg";
import imageWithColors17 from "../imgs/bricks/17.jpg";
import imageWithColors18 from "../imgs/bricks/18.jpg";
import imageWithColors19 from "../imgs/bricks/19.jpg";
import imageWithColors20 from "../imgs/bricks/20.jpg";
import imageWithColors21 from "../imgs/bricks/21.jpg";

import placeImage1 from "../imgs/bricks/1.1.jpg";
import placeImage2 from "../imgs/bricks/2.2.jpg";
import placeImage3 from "../imgs/bricks/3.3.jpg";
import placeImage4 from "../imgs/bricks/4.4.jpg";
import placeImage5 from "../imgs/bricks/5.5.jpg";
import placeImage6 from "../imgs/bricks/6.6.jpg";
import placeImage7 from "../imgs/bricks/7.7.jpg";
import placeImage8 from "../imgs/bricks/8.8.jpg";
import placeImage9 from "../imgs/bricks/9.9.jpg";
import placeImage10 from "../imgs/bricks/10.10.jpg";
import placeImage11 from "../imgs/bricks/11.11.jpg";
import placeImage12 from "../imgs/bricks/12.12.jpg";
import placeImage13 from "../imgs/bricks/13.13.jpg";
import placeImage14 from "../imgs/bricks/14.14.jpg";
import placeImage15 from "../imgs/bricks/15.15.jpg";
import placeImage16 from "../imgs/bricks/16.16.jpg";
import placeImage17 from "../imgs/bricks/17.17.jpg";
import placeImage18 from "../imgs/bricks/18.18.jpg";
import placeImage19 from "../imgs/bricks/19.19.jpg";
import placeImage20 from "../imgs/bricks/20.20.jpg";
import placeImage21 from "../imgs/bricks/21.21.jpg";

import logo1 from "../imgs/logo/1.png";
import logo2 from "../imgs/logo/2.png";
import logo3 from "../imgs/logo/3.png";
import logo4 from "../imgs/logo/4.png";
import logo5 from "../imgs/logo/5.png";
import logo6 from "../imgs/logo/6.png";
import logo7 from "../imgs/logo/7.png";
import logo8 from "../imgs/logo/8.png";
import logo9 from "../imgs/logo/9.png";
import logo10 from "../imgs/logo/10.png";
import logo11 from "../imgs/logo/11.png";
import logo12 from "../imgs/logo/12.png";
import logo13 from "../imgs/logo/13.png";
import logo14 from "../imgs/logo/14.png";
import logo15 from "../imgs/logo/15.png";
import logo16 from "../imgs/logo/16.png";
import logo17 from "../imgs/logo/17.png";
import logo18 from "../imgs/logo/18.png";
import logo19 from "../imgs/logo/19.png";
import logo20 from "../imgs/logo/20.png";
import logo21 from "../imgs/logo/21.png";

import txtName1 from "../imgs/bricks/name1.png";
import txtName2 from "../imgs/bricks/name2.png";
import txtName3 from "../imgs/bricks/name3.png";
import txtName4 from "../imgs/bricks/name4.png";
import txtName5 from "../imgs/bricks/name5.png";
import txtName6 from "../imgs/bricks/name6.png";
import txtName7 from "../imgs/bricks/name7.png";
import txtName8 from "../imgs/bricks/name8.png";
import txtName9 from "../imgs/bricks/name9.png";
import txtName10 from "../imgs/bricks/name10.png";
import txtName11 from "../imgs/bricks/name11.png";
import txtName12 from "../imgs/bricks/name12.png";
import txtName13 from "../imgs/bricks/name13.png";
import txtName14 from "../imgs/bricks/name14.png";
import txtName15 from "../imgs/bricks/name15.png";
import txtName16 from "../imgs/bricks/name16.png";
import txtName17 from "../imgs/bricks/name17.png";
import txtName18 from "../imgs/bricks/name18.png";
import txtName19 from "../imgs/bricks/name19.png";
import txtName20 from "../imgs/bricks/name20.png";
import txtName21 from "../imgs/bricks/name21.png";

import clayImage1 from "../imgs/bricks/clay_1.png";
import clayImage2 from "../imgs/bricks/clay_2.png";
import clayImage3 from "../imgs/bricks/clay_3.png";
import clayImage4 from "../imgs/bricks/clay_4.png";
import clayImage5 from "../imgs/bricks/clay_5.png";
import clayImage6 from "../imgs/bricks/clay_6.png";
import clayImage7 from "../imgs/bricks/clay_7.png";

import clayImage1_1 from "../imgs/bricks/clay_1.1.png";
import clayImage2_2 from "../imgs/bricks/clay_2.2.png";
import clayImage3_3 from "../imgs/bricks/clay_3.3.png";
import clayImage4_4 from "../imgs/bricks/clay_4.4.png";
import clayImage5_5 from "../imgs/bricks/clay_5.5.png";
import clayImage6_6 from "../imgs/bricks/clay_6.6.png";
import clayImage7_7 from "../imgs/bricks/clay_7.7.png";

import cName01 from "../imgs/bricks/cName01.png";
import cName02 from "../imgs/bricks/cName02.png";
import cName03 from "../imgs/bricks/cName03.png";
import cName04 from "../imgs/bricks/cName04.png";
import cName05 from "../imgs/bricks/cName05.png";
import cName06 from "../imgs/bricks/cName06.png";
import cName07 from "../imgs/bricks/cName07.png";

export const bricks = [
  {
    id: "burn_terrakota",
    name: "Burn Terrakota",
    mainImage: mainImage1,
    imageWithColors: imageWithColors1,
    placeImage: placeImage1,
    txtName: txtName1,
    navLogo: logo1,
    color: "#6d554c",
    category: "1",
  },
  {
    id: "terrakota",
    name: "Terrakota",
    mainImage: mainImage2,
    imageWithColors: imageWithColors2,
    placeImage: placeImage2,
    txtName: txtName2,
    navLogo: logo2,
    color: "#817068",
    category: "1",
  },
  {
    id: "aksi_grey",
    name: "Aksi Grey",
    mainImage: mainImage3,
    imageWithColors: imageWithColors3,
    placeImage: placeImage3,
    txtName: txtName3,
    navLogo: logo3,
    color: "#78797c",
    category: "1",
  },
  {
    id: "memesis",
    name: "Memesis",
    mainImage: mainImage4,
    imageWithColors: imageWithColors4,
    placeImage: placeImage4,
    txtName: txtName4,
    navLogo: logo4,
    color: "#513820",
    category: "1",
  },
  {
    id: "romas",
    name: "Roma's",
    mainImage: mainImage5,
    imageWithColors: imageWithColors5,
    placeImage: placeImage5,
    txtName: txtName5,
    navLogo: logo5,
    color: "#70543f",
    category: "1",
  },
  {
    id: "bora",
    name: "Bora",
    mainImage: mainImage6,
    imageWithColors: imageWithColors6,
    placeImage: placeImage6,
    txtName: txtName6,
    navLogo: logo6,
    color: "#876e52",
    category: "1",
  },
  {
    id: "aksi_red",
    name: "Aksi Red",
    mainImage: mainImage7,
    imageWithColors: imageWithColors7,
    placeImage: placeImage7,
    txtName: txtName7,
    navLogo: logo7,
    color: "#533c3d",
    category: "1",
  },
  {
    id: "black_brick",
    name: "Black Brick",
    mainImage: mainImage8,
    imageWithColors: imageWithColors8,
    placeImage: placeImage8,
    txtName: txtName8,
    navLogo: logo8,
    color: "#444445",
    category: "1",
  },
  {
    id: "brows_mix",
    name: "Brown Mix",
    mainImage: mainImage9,
    imageWithColors: imageWithColors9,
    placeImage: placeImage9,
    txtName: txtName9,
    navLogo: logo9,
    color: "#544137",
    category: "1",
  },
  {
    id: "aksi",
    name: "Aksi",
    mainImage: mainImage10,
    imageWithColors: imageWithColors10,
    placeImage: placeImage10,
    txtName: txtName10,
    navLogo: logo10,
    color: "#887c66",
    category: "1",
  },
  {
    id: "old_english",
    name: "Old English",
    mainImage: mainImage11,
    imageWithColors: imageWithColors11,
    placeImage: placeImage11,
    txtName: txtName11,
    navLogo: logo11,
    color: "#705e51",
    category: "1",
  },
  {
    id: "full_black",
    name: "Full Black",
    mainImage: mainImage12,
    imageWithColors: imageWithColors12,
    placeImage: placeImage12,
    txtName: txtName12,
    navLogo: logo12,
    color: "#454547",
    category: "1",
  },
  {
    id: "red_brick",
    name: "Red Brick",
    mainImage: mainImage13,
    imageWithColors: imageWithColors13,
    placeImage: placeImage13,
    txtName: txtName13,
    navLogo: logo13,
    color: "#332423",
    category: "1",
  },
  {
    id: "black_brown",
    name: "Black Brown",
    mainImage: mainImage14,
    imageWithColors: imageWithColors14,
    placeImage: placeImage14,
    txtName: txtName14,
    navLogo: logo14,
    color: "#33261e",
    category: "1",
  },
  {
    id: "grey",
    name: "Grey",
    mainImage: mainImage15,
    imageWithColors: imageWithColors15,
    placeImage: placeImage15,
    txtName: txtName15,
    navLogo: logo15,
    color: "#a4a6a8",
    category: "1",
  },
  {
    id: "burn_yellow",
    name: "Burn Yellow",
    mainImage: mainImage16,
    imageWithColors: imageWithColors16,
    placeImage: placeImage16,
    txtName: txtName16,
    navLogo: logo16,
    color: "#746351",
    category: "1",
  },
  {
    id: "mix_brick",
    name: "Mix Brick",
    mainImage: mainImage17,
    imageWithColors: imageWithColors17,
    placeImage: placeImage17,
    txtName: txtName17,
    navLogo: logo17,
    color: "#856c5a",
    category: "1",
  },
  {
    id: "berlin_black",
    name: "Berlin Black",
    mainImage: mainImage18,
    imageWithColors: imageWithColors18,
    placeImage: placeImage18,
    txtName: txtName18,
    navLogo: logo18,
    color: "#ab8d73",
    category: "1",
  },
  {
    id: "orange_brick",
    name: "Orange Brick",
    mainImage: mainImage19,
    imageWithColors: imageWithColors19,
    placeImage: placeImage19,
    txtName: txtName19,
    navLogo: logo19,
    color: "#ac6922",
    category: "1",
  },
  {
    id: "red_burn",
    name: "Red Burn",
    mainImage: mainImage20,
    imageWithColors: imageWithColors21,
    placeImage: placeImage21,
    txtName: txtName20,
    navLogo: logo21,
    color: "#542718",
    category: "1",
  },
  {
    id: "grey_black",
    name: "Grey Black",
    mainImage: mainImage21,
    imageWithColors: imageWithColors20,
    placeImage: placeImage20,
    txtName: txtName21,
    navLogo: logo20,
    color: "#6f7073",
    category: "1",
  },
  {
    id: "aksi_brick_01",
    name: "aksi brick 01",
    mainImage: clayImage1,
    imageWithColors: clayImage1,
    placeImage: clayImage1_1,
    txtName: cName01,
    navLogo: logo21,
    color: "#774a30",
    category: "2",
  },
  {
    id: "aksi_brick_02",
    name: "aksi brick 02",
    mainImage: clayImage2,
    imageWithColors: clayImage2,
    placeImage: clayImage2_2,
    txtName: cName02,
    navLogo: logo9,
    color: "#745b3c",
    category: "2",
  },
  {
    id: "aksi_brick_03",
    name: "aksi brick 03",
    mainImage: clayImage3,
    imageWithColors: clayImage3,
    placeImage: clayImage3_3,
    txtName: cName03,
    navLogo: logo19,
    color: "#b18e45",
    category: "2",
  },
  {
    id: "aksi_brick_04",
    name: "aksi brick 04",
    mainImage: clayImage4,
    imageWithColors: clayImage4,
    placeImage: clayImage4_4,
    txtName: cName04,
    navLogo: logo19,
    color: "#af7c4b",
    category: "2",
  },
  {
    id: "aksi_brick_05",
    name: "aksi brick 05",
    mainImage: clayImage5,
    imageWithColors: clayImage5,
    placeImage: clayImage5_5,
    txtName: cName05,
    navLogo: logo19,
    color: "#d7904d",
    category: "2",
  },
  {
    id: "aksi_brick_06",
    name: "aksi brick 06",
    mainImage: clayImage6,
    imageWithColors: clayImage6,
    placeImage: clayImage6_6,
    txtName: cName06,
    navLogo: logo19,
    color: "#b6794f",
    category: "2",
  },
  {
    id: "aksi_brick_07",
    name: "aksi brick 07",
    mainImage: clayImage7,
    imageWithColors: clayImage7,
    placeImage: clayImage7_7,
    txtName: cName07,
    navLogo: logo18,
    color: "#906f45",
    category: "2",
  },
];

export const moreInfo = {
  al: {
    h1: "Specifikat teknike",
    p1: "Tullë argjilore",
    l1: "Lartësia",
    l1_1: "65mm",
    l3: "Gjatësia",
    l3_1: "210mm",
    l2: "Gjerësia",
    l2_1: "105mm",
    l4: "Forca ne shtypje:",
    l4_1: "30.8 N/mm2",
    l5: "Ujëthithja:",
    l5_1: "11.5%",
    l6: "Forma(tekstura)",
    l6_1: "E ashpër(egër)",
    h2: "Garancion 60 vite",
    h2_1: "Të certifikuara me kualitet Europian CE",
    info_concrete: {
      h2: "Tullë betoni",
      p1: "Tulla dekoruese të kualitetit të lartë me dimensione:",
      p2: "Të përshtatshme për ambiente te brendshme dhe të jashtme, rezistente ndaj çdo temperature",
      p3: "Garancion 60 vite",
      p4: "Të certifikuara me kualitet Europian CE",
    },
    nav: {
      nav1: "Kryefaqja",
      nav2: "Katalogu",
      nav3: "Info",
      nav4: "Kontakti",
    },
    brickTypes: {
      brick1: "Tulla Betoni",
      brick2: "Tulla Argjile",
    },
    PP_1: "Me shume info",
    PP_2: "Porosit tani",
    contact: {
      h1: "Na kontaktoni",
      p1: "Klikoni ketu per te na telefonuar",
      p2: "Dergoni nje mesazh ne WhatsApp",
      p3: "Derogoni nje mesazh ne Viber",
      p4: "Na dergoni nje email",
    },
  },
  en: {
    h1: "Specific technique",
    p1: "Clay brick",
    l1: "Hight",
    l1_1: "65mm",
    l3: "Length",
    l3_1: "210mm",
    l2: "Width",
    l2_1: "105mm",
    l4: "Compressive strength:",
    l4_1: "30.8 N/mm2",
    l5: "Water absorption:",
    l5_1: "11.5%",
    l6: "Shape (texture)",
    l6_1: "Fierce",
    h2: "60 year guarantee",
    h2_1: "Certified with European CE quality",
    info_concrete: {
      h2: "Concrete bricks",
      p1: "High quality decorative bricks with dimensions:",
      p2: "Suitable for indoor and outdoor environments, resistant to any temperature",
      p3: "60 year warranty",
      p4: "Certified with European CE quality",
    },
    nav: {
      nav1: "Home",
      nav2: "Catalog",
      nav3: "Info",
      nav4: "Contact",
    },
    brickTypes: {
      brick1: "Concrete bricks",
      brick2: "Clay bricks",
    },
    PP_1: "More Info",
    PP_2: "Order now",
    contact: {
      h1: "Contact us",
      p1: "Click here to call us",
      p2: "Send us a message on WhatsApp",
      p3: "Send us a message on Viber",
      p4: "Send us an email",
    },
  },
  de: {
    h1: "Spezifische Technik",
    p1: "Lehmziegel",
    l1: "Höhe",
    l1_1: "65mm",
    l3: "Länge",
    l3_1: "210mm",
    l2: "Breite",
    l2_1: "105mm",
    l4: "Druckfestigkeit:",
    l4_1: "30.8 N/mm2",
    l5: "Wasseraufnahme:",
    l5_1: "11.5%",
    l6: "Form (Textur)",
    l6_1: "Erbittert",
    h2: "60 Jahre Garantie",
    h2_1: "Zertifiziert mit europäischer CE-Qualität",
    info_concrete: {
      h2: "Betonziegel",
      p1: "Hochwertige Dekosteine mit den Maßen:",
      p2: "Geeignet für Innen- und Außenbereiche, beständig gegen jede Temperatur",
      p3: "60 Jahre Garantie",
      p4: "Zertifiziert mit europäischer Qualität CE",
    },
    nav: {
      nav1: "Home",
      nav2: "Katalog",
      nav3: "Infos",
      nav4: "Kontakt",
    },
    brickTypes: {
      brick1: "Betonziegel",
      brick2: "Lehmziegel",
    },
    PP_1: "Mehr Infos",
    PP_2: "Jetzt bestellen",
    contact: {
      h1: "Kontaktieren Sie uns",
      p1: "Klicken Sie hier, um uns anzurufen",
      p2: "Senden Sie uns eine Nachricht auf WhatsApp",
      p3: "Senden Sie uns eine Nachricht auf Viber",
      p4: "Senden Sie uns eine E-Mail",
    },
  },
  fr: {
    h1: "Technique spécifique",
    p1: "Brique d'argile",
    l1: "Haut",
    l1_1: "65mm",
    l3: "Longueur",
    l3_1: "210mm",
    l2: "Largeur",
    l2_1: "105mm",
    l4: "Résistance à la compression:",
    l4_1: "30.8 N/mm2",
    l5: "Absorption de l'eau:",
    l5_1: "11.5%",
    l6: "Forme (texture)",
    l6_1: "Féroce",
    h2: "Garantie 60 ans",
    h2_1: "Certifié avec la qualité européenne CE",
    info_concrete: {
      h2: "Briques en béton",
      p1: "Briques décoratives de haute qualité aux dimensions:",
      p2: "Convient aux environnements intérieurs et extérieurs, résistant à toute température",
      p3: "Garantie 60 ans",
      p4: "Certifié qualité européenne CE",
    },
    nav: {
      nav1: "Home",
      nav2: "catalogue",
      nav3: "Info",
      nav4: "Contact",
    },
    brickTypes: {
      brick1: "Briques en béton",
      brick2: "Briques d'argile",
    },
    PP_1: "Plus d'informations",
    PP_2: "Commander",
    contact: {
      h1: "Contactez nous",
      p1: "Cliquez ici pour nous appeler",
      p2: "Envoyez-nous un message sur WhatsApp",
      p3: "Envoyez-nous un message sur Viber",
      p4: "Envoyez-nous un email",
    },
  },
};
