import "./App.css";
import React, { useEffect } from "react";
import { Routes, Route, useLocation, useNavigationType } from "react-router-dom";

import { bricks } from "./assets/js/data";

// Pages
import Homepage from "./pages/HomePage";
import Catalog from "./pages/Catalog";
import Product from "./pages/Product";
import Contact from "./pages/Contact";
import Info from "./pages/Info";

function App() {
  const location = useLocation();
  const navType = useNavigationType();
  useEffect(() => {
    if (navType !== "POP") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [location]);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Homepage bricks={bricks} />} />
        <Route path="/catalog/:id" element={<Catalog bricks={bricks} />} />
        <Route path="/:id" element={<Product bricks={bricks} />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/info/:id" element={<Info />} />
      </Routes>
    </div>
  );
}

export default App;
