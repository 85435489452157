import React, { useEffect, useState } from "react";
import Banner from "../components/mainbanner/banner";
import Navbar from "../components/navbar/navbar";
import logo from "../assets/imgs/logo/logo_origjinal.png";
import banner from "../assets/imgs/background2.jpg";
import "../assets/css/contact.css";
import { moreInfo } from "../assets/js/data";
import viberIcon from "../assets/imgs/logo/viber.png";
import whatsappIcon from "../assets/imgs/logo/whatsapp.png";
import gmailIcon from "../assets/imgs/logo/gmail.svg";
import callIcon from "../assets/imgs/logo/call.png";

export default function Contact() {
  const images = [banner, banner];
  const theme = {
    color: "rgba(218, 0, 0, 0.8)",
    background: "",
    animation: "banner 60s linear infinite",
    logoImage: logo,
  };
  const number = "38349677446";
  const email = "aksitulla.llc@gmail.com";
  const [selectedLanguage, setSelectedLanguage] = useState("al");
  const handleLangChange = (lang) => {
    setSelectedLanguage(lang);
  };

  useEffect(() => {
    setSelectedLanguage(localStorage.getItem("lang"));
  }, []);
  return (
    <>
      <Banner images={images} theme={theme} />
      <Navbar theme={theme} onLangChange={handleLangChange} />
      <div className="contact-container">
        <div className="contact-content">
          <div>
            <h1>{moreInfo[selectedLanguage].contact.h1}</h1>
            <a href={"tel: +" + number}>
              <img src={callIcon} alt="callIcon" /> {moreInfo[selectedLanguage].contact.p1}
            </a>
            <a href={"https://api.whatsapp.com/send?phone=" + number}>
              <img src={whatsappIcon} alt="whatsappIcon" />
              {moreInfo[selectedLanguage].contact.p2}
            </a>
            <a href={"viber://contact?number=%2B" + number}>
              <img src={viberIcon} alt="viberIcon" /> {moreInfo[selectedLanguage].contact.p3}
            </a>
            <a href={"mailto:" + email}>
              <img src={gmailIcon} alt="gmailIcon" /> {moreInfo[selectedLanguage].contact.p4}
            </a>
          </div>
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11738.401123529415!2d21.1834691!3d42.6486332!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13549fc7e62a289d%3A0x7c4ae46b070839f5!2sAksi%20Projekt!5e0!3m2!1sen!2s!4v1683041954957!5m2!1sen!2s"
            width="600"
            height="450"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </>
  );
}
