import React from "react";
import logo from "../assets/imgs/logo/logo_origjinal.png";

export default function LoaderComponent() {
  const mainStyle = {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    background: "rgb(225 225 225)",
    zIndex: "9999",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
  const imageStyle = {
    width: "200px",
    height: "200px",
    marginBottom: "-20px",
  };

  return (
    <div style={mainStyle}>
      <img style={imageStyle} src={logo} alt="logo" />
      <div className="spinner-container">
        <div className="loading-spinner"></div>
      </div>
    </div>
  );
}
