import React, { useEffect, useState } from "react";
import Banner from "../components/mainbanner/banner";
import Navbar from "../components/navbar/navbar";
import banner from "../assets/imgs/background2.jpg";
import { useParams } from "react-router-dom";
import "../assets/css/product.css";
import LoaderComponent from "../components/LoaderComponent";
import viberIcon from "../assets/imgs/logo/viber.png";
import whatsappIcon from "../assets/imgs/logo/whatsapp.png";
import gmailIcon from "../assets/imgs/logo/gmail.svg";
import callIcon from "../assets/imgs/logo/call.png";
import { moreInfo } from "../assets/js/data";
import brickImg from "../assets/imgs/brick_img.png";

export default function Product({ bricks }) {
  const { id } = useParams();
  const [brick, setBrick] = useState({});
  const [img0, setImg0] = useState(false);
  const [img1, setImg1] = useState(false);
  const [toogleOrder, setToogleOrder] = useState(false);
  const [toogleInfo, setToogleInfo] = useState(false);
  const [isClay, setIsClay] = useState(false);
  const images = [banner, banner];
  const theme = {
    color: `${brick.color}cc`,
    background: `linear-gradient(180deg, rgba(0,0,0,0) 50%, ${brick.color}cc 83%)`,
    animation: "banner 60s linear infinite",
    logoImage: brick.navLogo,
  };
  const number = "38349677446";
  const email = "aksitulla.llc@gmail.com";
  const [selectedImage, setSelectedImage] = useState(0);
  const [moreInfoText, setMoreInfoText] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState("al");
  const handleLangChange = (lang) => {
    setSelectedLanguage(lang);
  };
  const handleModal = (e) => {
    if (e.target.className.includes("product-order-container")) {
      setToogleOrder(false);
      setToogleInfo(false);
    }
  };

  useEffect(() => {
    setSelectedLanguage(localStorage.getItem("lang"));
    setMoreInfoText(moreInfo[selectedLanguage]);
    setBrick(bricks.find((brick) => brick.id === id));

    document.addEventListener("click", handleModal);

    return () => {
      document.removeEventListener("click", handleModal);
    };
  }, []);

  useEffect(() => {
    setMoreInfoText(moreInfo[selectedLanguage]);
  }, [selectedLanguage]);

  useEffect(() => {
    if (brick.category === "2") {
      setIsClay(true);
    } else {
      setIsClay(false);
    }
  }, [brick]);

  return (
    <>
      {!img0 && !img1 && <LoaderComponent />}
      <Banner images={images} theme={theme} />
      <Navbar theme={theme} onLangChange={handleLangChange} />
      <div className={isClay ? "product-container product-container-clay" : "product-container"}>
        {window.innerWidth > 1023 && (
          <>
            <div className={img0 ? "product-image active" : "product-image"}>
              <img
                src={brick.imageWithColors}
                alt="variants"
                onLoad={() => {
                  setImg0(true);
                }}
              />
            </div>
            <div className={img1 ? "product-image active" : "product-image"}>
              <img
                src={brick.placeImage}
                alt="place"
                onLoad={() => {
                  setImg1(true);
                }}
              />
            </div>
            {img0 && img1 && (
              <div className="header-content-bricks product-brick-info" style={{ backgroundColor: brick.color }}>
                <div className="header-content-brick active" style={{ backgroundImage: `url(${brick.txtName})` }}></div>
              </div>
            )}
          </>
        )}
        {window.innerWidth < 1024 && selectedImage === 0 && (
          <div className={img0 ? "product-image active" : "product-image"}>
            <img
              src={brick.imageWithColors}
              alt="variants"
              onLoad={() => {
                setImg0(true);
              }}
            />
          </div>
        )}
        {window.innerWidth < 1024 && selectedImage === 1 && (
          <div className={img1 ? "product-image active" : "product-image"}>
            <img
              src={brick.placeImage}
              alt="place"
              onLoad={() => {
                setImg1(true);
              }}
            />
          </div>
        )}

        {window.innerWidth < 1024 && (
          <>
            <div className="mobile-image-select">
              <div
                className={selectedImage === 0 ? "mobile-image-select-item active" : "mobile-image-select-item"}
                onClick={() => {
                  setSelectedImage(0);
                }}
              >
                <img src={brick.imageWithColors} alt="" />
              </div>
              <div
                className={selectedImage === 1 ? "mobile-image-select-item active" : "mobile-image-select-item"}
                onClick={() => {
                  setSelectedImage(1);
                }}
              >
                <img src={brick.placeImage} alt="" />
              </div>
            </div>
            <div className="header-content-bricks product-brick-info" style={{ backgroundColor: brick.color }}>
              <div className="header-content-brick active" style={{ backgroundImage: `url(${brick.txtName})` }}></div>
            </div>
          </>
        )}

        <div
          className="product-button-container clay-more-info"
          style={{ backgroundColor: brick.color }}
          onClick={() => {
            setToogleInfo(true);
          }}
        >
          <p>{moreInfo[selectedLanguage]?.PP_1}</p>
        </div>

        <div
          className="product-button-container"
          style={{ backgroundColor: brick.color }}
          onClick={() => {
            setToogleOrder(true);
          }}
        >
          <p>{moreInfo[selectedLanguage]?.PP_2}</p>
        </div>

        <div className={toogleOrder ? "product-order-container active" : "product-order-container"}>
          <div className="product-order-content">
            <div
              className="modal-close-icon"
              onClick={() => {
                setToogleOrder(false);
              }}
            ></div>
            <div className="product-order-image">
              <img src={brick?.mainImage} alt="brick" />
            </div>
            <div className="product-order-socials">
              <a href={"tel: +" + number}>
                <img src={callIcon} alt="callIcon" />
              </a>
              <a href={"https://api.whatsapp.com/send?phone=" + number + "&text= " + window.location.href}>
                <img src={whatsappIcon} alt="whatsappIcon" />
              </a>
              <a href={"viber://contact?number=%2B" + number}>
                <img src={viberIcon} alt="viberIcon" />
              </a>
              <a href={"mailto:" + email}>
                <img src={gmailIcon} alt="gmailIcon" />
              </a>
            </div>
          </div>
        </div>
        {isClay && (
          <div className={toogleInfo ? "product-order-container active" : "product-order-container"}>
            <div className="product-order-content product-moreInfo-content">
              <div
                className="modal-close-icon"
                onClick={() => {
                  setToogleInfo(false);
                }}
              ></div>
              <h1>{moreInfoText?.h1}</h1>
              <div className="product-moreInfo-text">
                <p className="bold">{moreInfoText?.p1}</p>
              </div>
              <div className="product-moreInfo-text">
                <p>{moreInfoText?.l1}</p>
                <p>{moreInfoText?.l1_1}</p>
              </div>
              <div className="product-moreInfo-text">
                <p>{moreInfoText?.l2}</p>
                <p>{moreInfoText?.l2_1}</p>
              </div>
              <div className="product-moreInfo-text">
                <p>{moreInfoText?.l3}</p>
                <p>{moreInfoText?.l3_1}</p>
              </div>
              <div className="product-moreInfo-text">
                <p>{moreInfoText?.l4}</p>
                <p>{moreInfoText?.l4_1}</p>
              </div>
              <div className="product-moreInfo-text">
                <p>{moreInfoText?.l5}</p>
                <p>{moreInfoText?.l5_1}</p>
              </div>
              <div className="product-moreInfo-text">
                <p>{moreInfoText?.l6}</p>
                <p>{moreInfoText?.l6_1}</p>
              </div>
              <div className="product-moreInfo-text">
                <p className="bold">{moreInfoText?.h2}</p>
                <p className="bold">{moreInfoText?.h2_1}</p>
              </div>
            </div>
          </div>
        )}
        {!isClay && (
          <div className={toogleInfo ? "product-order-container active" : "product-order-container"}>
            <div className="product-order-content product-moreInfo-content p1-concrete">
              <div
                className="modal-close-icon"
                onClick={() => {
                  setToogleInfo(false);
                }}
              ></div>
              <h1>{moreInfoText?.h1}</h1>
              <div className="product-moreInfo-text">
                <p className="bold">{moreInfoText?.info_concrete?.h2}</p>
              </div>
              <div className="product-moreInfo-text">
                <p>{moreInfoText?.info_concrete?.p1}</p>
              </div>
              <div className="product-image-infos">
                <div className="product-image-info">
                  <span className="info info-1">6cm</span>
                  <span className="info info-2">20cm</span>
                  <span className="info info-3">2cm</span>
                  <img src={brickImg} alt="" />
                </div>
                <div className="product-image-info">
                  <span className="info info-1">6cm</span>
                  <span className="info info-2">20cm</span>
                  <span className="info info-3">4.5cm</span>
                  <img src={brickImg} alt="" />
                </div>
                <div className="product-image-info">
                  <span className="info info-1">6cm</span>
                  <span className="info info-2">20cm</span>
                  <span className="info info-3">6cm</span>
                  <img src={brickImg} alt="" />
                </div>
              </div>
              <div className="product-moreInfo-text">
                <p>{moreInfoText?.info_concrete?.p2}</p>
              </div>
              <div className="product-moreInfo-text">
                <p className="bold">{moreInfoText?.info_concrete?.p3}</p>
                <p className="bold">{moreInfoText?.info_concrete?.p4}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
