import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import { moreInfo } from "../../assets/js/data";
import alb from "../../assets/imgs/alb.png";
import eng from "../../assets/imgs/en.png";
import de from "../../assets/imgs/de.png";
import fr from "../../assets/imgs/fr.png";

export default function Navbar(props) {
  const [burger, setBurger] = useState(false);

  const handleBurger = () => {
    setBurger(!burger);
  };
  const [subLinks, setSubLinks] = useState(false);
  const [subLinks2, setSubLinks2] = useState(false);
  const [toogleLang, setToogleLang] = useState(false);
  const [activeLang, setActiveLang] = useState("al");
  const [activeLangImg, setActiveLangImg] = useState(alb);

  const handleSubLinks = () => {
    setSubLinks(!subLinks);
  };
  const handleSubLinks2 = () => {
    setSubLinks2(!subLinks2);
  };

  const handleClickOutside = (e) => {
    if (!e.target.closest(".navbar")) {
      setSubLinks(false);
      setSubLinks2(false);
      setBurger(false);
      setToogleLang(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("lang")) {
      setActiveLang(localStorage.getItem("lang"));
      if (localStorage.getItem("lang") === "al") setActiveLangImg(alb);
      if (localStorage.getItem("lang") === "en") setActiveLangImg(eng);
      if (localStorage.getItem("lang") === "de") setActiveLangImg(de);
      if (localStorage.getItem("lang") === "fr") setActiveLangImg(fr);
    } else {
      localStorage.setItem("lang", "al");
    }
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleLang = (lang) => {
    setActiveLang(lang);
    if (lang === "al") setActiveLangImg(alb);
    if (lang === "en") setActiveLangImg(eng);
    if (lang === "de") setActiveLangImg(de);
    if (lang === "fr") setActiveLangImg(fr);
    localStorage.setItem("lang", lang);
    setToogleLang(false);
    if (props.onLangChange) props.onLangChange(lang);
  };

  return (
    <div className="navbar">
      <div className="navbar-lang-container">
        <div
          className="navbar-lang-selected"
          onClick={() => {
            setToogleLang(true);
          }}
        >
          <img src={activeLangImg} alt="" /> {activeLang}
        </div>
        <div className={toogleLang ? "navbar-lang-options active" : "navbar-lang-options"} style={{ backgroundColor: props.theme.color }}>
          <div
            className="navbar-lang"
            onClick={() => {
              handleLang("al");
            }}
          >
            <img src={alb} alt="alb" /> AL
          </div>
          <div
            className="navbar-lang"
            onClick={() => {
              handleLang("en");
            }}
          >
            <img src={eng} alt="eng" />
            EN
          </div>
          <div
            className="navbar-lang"
            onClick={() => {
              handleLang("de");
            }}
          >
            <img src={de} alt="de" />
            DE
          </div>
          <div
            className="navbar-lang"
            onClick={() => {
              handleLang("fr");
            }}
          >
            <img src={fr} alt="fr" />
            FR
          </div>
        </div>
      </div>
      <div className="navbar-left" style={{ backgroundColor: props.theme.color }}>
        <div className="page-links">
          <Link className="page-link" to="/">
            {moreInfo[activeLang].nav.nav1}
          </Link>
          <div className="page-link" onClick={handleSubLinks2}>
            {moreInfo[activeLang].nav.nav3}
          </div>
        </div>
        <div className={subLinks2 ? "page-sub-links left active" : "page-sub-links left"} style={{ backgroundColor: props.theme.color }}>
          <Link className="page-link" to="/info/1">
            {moreInfo[activeLang].brickTypes.brick1}
          </Link>
          <Link className="page-link" to="/info/2">
            {moreInfo[activeLang].brickTypes.brick2}
          </Link>
        </div>
      </div>
      <Link to="/" className="navbar-center">
        <div className="logo-container" style={{ backgroundImage: `url(${props.theme.logoImage}` }}></div>
      </Link>
      <div className="navbar-right" style={{ backgroundColor: props.theme.color }}>
        <div className="page-links">
          <div className="page-link" onClick={handleSubLinks}>
            {moreInfo[activeLang].nav.nav2}
          </div>
          <Link className="page-link" to="/contact">
            {moreInfo[activeLang].nav.nav4}
          </Link>
        </div>
        <div className={subLinks ? "page-sub-links active" : "page-sub-links"} style={{ backgroundColor: props.theme.color }}>
          <Link className="page-link" to="/catalog/1">
            {moreInfo[activeLang].brickTypes.brick1}
          </Link>
          <Link className="page-link" to="/catalog/2">
            {moreInfo[activeLang].brickTypes.brick2}
          </Link>
        </div>
      </div>
      <div className="navbar-mobile" style={{ backgroundColor: props.theme.color }}>
        <div className={burger ? "navbar-burger active" : "navbar-burger"} onClick={handleBurger}>
          <div className="burger"></div>
        </div>
        <div className={burger ? "page-links active" : "page-links"}>
          <Link className="page-link" to="/">
            {moreInfo[activeLang].nav.nav1}
          </Link>
          <div className="page-link" onClick={handleSubLinks}>
            {moreInfo[activeLang].nav.nav2}
          </div>
          <div className={subLinks ? "page-sub-links active" : "page-sub-links"} style={{ backgroundColor: props.theme.color }}>
            <Link className="page-link" to="/catalog/1">
              {moreInfo[activeLang].brickTypes.brick1}
            </Link>
            <Link className="page-link" to="/catalog/2">
              {moreInfo[activeLang].brickTypes.brick2}
            </Link>
          </div>
          <div className="page-link" onClick={handleSubLinks2}>
            {moreInfo[activeLang].nav.nav3}
          </div>
          <div className={subLinks2 ? "page-sub-links left active" : "page-sub-links left"} style={{ backgroundColor: props.theme.color }}>
            <Link className="page-link" to="/info/1">
              {moreInfo[activeLang].brickTypes.brick1}
            </Link>
            <Link className="page-link" to="/info/2">
              {moreInfo[activeLang].brickTypes.brick2}
            </Link>
          </div>
          <Link className="page-link" to="/contact">
            {moreInfo[activeLang].nav.nav4}
          </Link>
        </div>
      </div>
    </div>
  );
}
